var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.VideoDetail && Object.keys(_vm.VideoDetail).length)?_c('div',{staticClass:"text-detail"},[_c('div',{staticClass:"content mt-8"},[_c('div',{staticClass:"text-detail__title",domProps:{"innerHTML":_vm._s(_vm.VideoDetail.name)}})]),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Библиотека', link: { name: 'Library' } },
        {
          name: 'Видеоматериалы',
          link: {
            name: 'VideoMaterials',
          },
        },
        {
          name: _vm.VideoDetail.name,
        } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-detail__row"},[_c('div',{staticClass:"text-detail__body"},[_c('div',{staticClass:"material__tags-wrap"},[_c('div',{staticClass:"material__tags"},[(_vm.VideoDetail.type)?_c('div',{staticClass:"material__tag"},[_vm._v(" "+_vm._s(_vm.VideoDetail.type.name)+" ")]):_vm._e(),_vm._l((_vm.VideoDetail.nosology),function(nosology){return _c('div',{key:("nos" + (nosology.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(nosology.title)}})}),_vm._l((_vm.VideoDetail.disease),function(disease){return _c('div',{key:("dis" + (disease.id)),staticClass:"material__tag",domProps:{"innerHTML":_vm._s(disease.title)}})}),(_vm.VideoDetail.is_new && false)?_c('div',{staticClass:"material__tag material__tag_new"},[_vm._v(" Новинка ")]):_vm._e()],2),(!_vm.$route.query.access)?_c('Favorite',{staticClass:"large",attrs:{"watchLater":_vm.VideoDetail.watch_later,"favorite":_vm.VideoDetail.favorite},on:{"watchLater":function($event){return _vm.watchLater(
                  _vm.VideoDetail.watch_later ? 'DELETE' : 'POST',
                  _vm.VideoDetail.slug,
                  null,
                  true
                )},"favorite":function($event){return _vm.favorite(
                  _vm.VideoDetail.favorite ? 'DELETE' : 'POST',
                  _vm.VideoDetail.slug,
                  null,
                  true
                )}}}):_vm._e()],1),_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.VideoDetail.video),expression:"VideoDetail.video"}],ref:"videoElement",attrs:{"controls":"","src":_vm.$root.host + _vm.VideoDetail.video.url,"poster":_vm.VideoDetail.announce
                ? _vm.$root.host + _vm.VideoDetail.announce.url
                : null}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.VideoDetail.description),expression:"VideoDetail.description"}],ref:"description",staticClass:"text-detail__subtitle",domProps:{"innerHTML":_vm._s(_vm.VideoDetail.description)}})]),_c('div',{staticClass:"sticky"},[_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"medicationsClick":_vm.medicationsClick,"calendarClick":_vm.calendarClick,"nosologyClick":_vm.nosologyClick}})],1)])]),(_vm.sliderItems.length)?_c('LibrarySlider',{attrs:{"nosology":_vm.VideoDetail.nosology[0] || '',"items":_vm.sliderItems,"video":true,"page":'VideoDetail'},on:{"clickMaterials":_vm.clickMaterials,"toNosol":_vm.toNosol}}):_vm._e()],1):(_vm.VideoDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):(_vm.VideoDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }